/*************fonts*************/
@font-face {
  font-family: Quicksand-Bold;
  src: url(../fonts/font/quicksand/Quicksand-Bold.ttf);
}

@font-face {
  font-family: Quicksand-Light;
  src: url(../fonts/font/quicksand/Quicksand-Light.ttf);

}

@font-face {
  font-family: Quicksand-Medium;
  src: url(../fonts/font/quicksand/Quicksand-Medium.ttf);
}

@font-face {
  font-family: Quicksand-Regular;
  src: url(../fonts/font/quicksand/Quicksand-Regular.ttf);
}

@font-face {
  font-family: Quicksand-SemiBold;
  src: url(../fonts/font/quicksand/Quicksand-SemiBold.ttf);


}

/*******************Body BG*********************/
/* body{
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  overflow-x: hidden;
} */

.body-page-agence {
  background: transparent linear-gradient(180deg, #FFFBF7 0%, #FFFBF7 50%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  font-family: Quicksand-Regular;
  color: #000;
}

.body-page-wp {
  background: transparent linear-gradient(180deg, #FBFCFD 0%, #F2F6FF 29%, #F9FCFF 49%, #FBFCFD 100%) 0% 0% no-repeat padding-box;
  font-family: Quicksand-Regular;
  color: #000;

}

.body-page-we {
  background: transparent linear-gradient(180deg, #FFFCFF 0%, #F8F7FF 50%, #FCFBFB 100%) 0% 0% no-repeat padding-box;
  font-family: Quicksand-Regular;
  color: #000;

}

.body-page-wfo {
  background: transparent linear-gradient(180deg, #FEFAFC 0%, #FBF9FC 27%, #FAF9FE 48%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  font-family: Quicksand-Regular;
  color: #000;

}

.body-page-wts {
  background: transparent linear-gradient(180deg, #F6FFFF 0%, #F7FDFF 49%, #FDFFFF 100%) 0% 0% no-repeat padding-box;
  font-family: Quicksand-Regular;
  color: #000;

}

.body-page-ref {
  background: transparent linear-gradient(180deg, #FFFBF7 0%, #FFFBF7 50%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  font-family: Quicksand-Regular;
  color: #000;

}

.body-page-contact {
  background: transparent linear-gradient(180deg, #FFFBF7 0%, #FFFBF7 50%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  font-family: Quicksand-Regular;
  color: #000;

}


/************header****************/
.header {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 37px #00000014;
  border-radius: 0px 0px 20px 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.box-header-commercial {
  text-align: end;

}

/* .box-header-support {
  text-align: right;

} */

.logo-header {
  margin-top: -19%;

}
.logo-header-en {
  width: 160px;
  margin-top: -21%;
}
.logo-header-footer-en {
  width: 142px;
}
.infoline-menu {
  font-size: 13px;
  text-align: end;
  font-family: Quicksand-bold;
}

.icone-menu {
  padding-right: 1%;
}

nav.navbar.navbar-expand-lg.fixed-top.clearHeader {
  height: 100px;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.navbar-brand {
  padding: 0 !important;
}
.priority-img {
  z-index: 1;
}

button.navbar-toggler.hide .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.196' height='22.311' viewBox='0 0 24.196 22.311'%3E%3Cpath id='XMLID_101_' d='M29.654,14.585H9.543a2.043,2.043,0,1,1,0-4.085H29.654a2.043,2.043,0,0,1,0,4.085Zm2.043,7.07a2.038,2.038,0,0,0-2.043-2.043H19.569a2.043,2.043,0,1,0,0,4.085H29.654A2.038,2.038,0,0,0,31.7,21.655Zm0,9.113a2.038,2.038,0,0,0-2.043-2.043H9.543a2.043,2.043,0,0,0,0,4.085H29.654A2.038,2.038,0,0,0,31.7,30.768Z' transform='translate(-7.5 -10.5)' fill='%2316254f'/%3E%3C/svg%3E%0A");
}

button.navbar-toggler.show .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='22.559' height='22.559' viewBox='0 0 22.559 22.559'%3e%3cg id='Groupe_164514' data-name='Groupe 164514' transform='translate(-314 -35.999)'%3e%3cpath id='XMLID_101_' d='M32.491%2c15.108H9.8a2.3%2c2.3%2c0%2c1%2c1%2c0-4.608H32.491a2.3%2c2.3%2c0%2c0%2c1%2c0%2c4.608Z' transform='translate(319.38 23.271) rotate(45)' fill='%23ed5f11'/%3e%3cpath id='XMLID_101_2' data-name='XMLID_101_' d='M24.991%2c0H2.3a2.3%2c2.3%2c0%2c1%2c0%2c0%2c4.608H24.991a2.3%2c2.3%2c0%2c0%2c0%2c0-4.608Z' transform='translate(336.559 39.258) rotate(135)' fill='%23ed5f11'/%3e%3c/g%3e%3c/svg%3e");
}

.navbar-toggler:focus {
  box-shadow: none;
}

nav.navbar.navbar-expand-lg.fixed-top {
  padding: 25px 25px;
  transition: .5s all;
}

nav.navbar.navbar-expand-lg.fixed-top.clearHeader {
  padding-top: 10px;
  padding-bottom: 10px;
  transition: .5s all;
}

nav.navbar.navbar-expand-lg.fixed-top.clearHeader a.navbar-brand img {
  width: 60%;
  transition: .5s all;
}

.clearHeader {
  height: 10vh;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 37px #00000014;
  border-radius: 0px 0px 20px 20px;

}

ul.navbar-nav.flag-lang.text-right {
  width: 5%;
}

.contact-wts {
  padding: 12px 8px;
  float: right;
  display: inline-block;
  font-size: 11px;
}



.alignement-nav {
  margin-top: 22px;
}

.dropdown-toggle::after {
  position: relative;
  top: 1px;
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-right: 2px solid black !important;
  border-top: 2px solid black !important;
  transform: rotate(135deg);
  border-left: 0 solid transparent !important;
  right: -15px;
}

.dropdown-toggle.active::after {
  border-right: 2px solid #E86012 !important;
  border-top: 2px solid #E86012 !important;
}

ul.navbar-nav.ml-auto.menu-middle a {
  color: #000000;
  font-size: 16px;
  padding-left: 35px;
  padding-right: 35px;
  font-family: Quicksand-Medium;
}

ul.navbar-nav.ml-auto.btn-language a {
  font-size: 12px !important;
  transition: .3s all !important;
  border: 1px solid #ECECEC !important;
  border-radius: 43px !important;
  padding: 8px 12px 10px 13px !important;

}

.navbar-item {
  display: block;
  float: left;
  width: 100px;
  height: 30px;
  text-align: center;
  color: white;
}

.nav-link {
  text-decoration: none;
  text-align: left;
  font-family: Quicksand-Medium;
  color: #C8C8C8;
}

.fr {
  color: #FFFFFF;
  background: #ED5F11 0% 0% no-repeat padding-box;
}

.nav-item-lang {
  margin-right: 3px;
}

.nav-link-lang {
  height: 38px;
  width: 38px;
}

/* .dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
} */

.dropdown-item.active,
.dropdown-item:active {
  color: #ED5F11 !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
}

.dropdown-menu.show {
  border-radius: 13px;
  box-shadow: 0px 15px 22px #0000001C !important;
}

.bloc-navbar {
  margin-left: 0px;
}

.heurs-travail {
  text-decoration: none;
}

a {
  font-family: Quicksand-Regular;
  font-size: 18px;
  color: #000;
}

/* ul.navbar-nav.ml-auto.menu-middle a:hover {
  color: #ED5F11;
  border: none;

} */

/* .navbar-brand:hover {
  border: none;
} */

a.nav-link.active {
  color: #ED5F11 !important;
  border-bottom: 2px solid #E86012;
}

.size-lang {
  height: 38px;
  width: 38px;
}

.space-lang {
  margin-right: 3px;
}

nav.navbar.navbar-expand-lg.bloc-navbar.container-header-2 {
  padding-bottom: 0;
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
  ul.navbar-nav.ml-auto.menu-middle a {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*******************section-footer*****************/
.footer-up {
  text-decoration: none;
  text-align: left;
  font-family: Quicksand-Regular;
  letter-spacing: 0px;
  color: #C8C8C8;
  top: 0px;
  position: absolute;
  right: 0;
}

.bg-footer-contact {
  background-color: #FFF7F1;
}

.footer-img {
  top: 33px;
  position: absolute;
}

.titre-footer {
  font-size: 22px;
}

.input-footer {
  border: none;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 17%);
  -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 17%);
  border-radius: 20px;
  margin-bottom: 28px;
  padding: 15px 15px 15px 45px;

}

.form-footer {
  width: 386px;
  height: 70px;
}

.footer .footer-form .form-group .submit {
  color: #fff !important;
  display: block;
  width: 110px;
  height: 48px;
  font-size: 14px;
  background: #1C72BD 0% 0% no-repeat;
  border-radius: 10px;

}

.footer-link {
  font-family: Quicksand-Medium;
  font-size: 14px;
  color: #000;
  display: flex;
  align-items: center;
}

.adr-footer {
  font-size: 14px;
  font-family: Quicksand-Regular;
  color: #000000;

}


.footer .footer-form .form-group input {
  border: none !important;
  color: #999999 !important;
  font-size: 14px;
  background: url(../../public/images/footer/contact/mail.svg) no-repeat left;
  background-size: 20px;
  background-position: 15px;
  border-radius: 10px;
  padding: 15px 15px 15px 50px;
  box-shadow: 10px 24px 54px #33333308;
}

.form-newsletter {
  margin-bottom: 20px;
  padding: 10px !important;
  border-radius: 10px;
}

.form-group.d-flex.form-newsletter {
  background-color: #fff;
}

.img-linkedIn {
  margin-left: 5%;
}


.condition-footer-label {
  font-size: 12px;
  font-family: Quicksand-Medium;
  color: #535353;
  padding-left: 6px;
}

.bloc-adr-footer {
  margin-top: -3%;

}

.footer-bloc-2 {
  display: flex;
  justify-content: center;
}

.footer-link:hover {
  color: #ED5F11;
  border: none;
}

.footer-bloc-3-end {
  width: 100%;
}

.footer-bloc-3-end .footer-link {
  justify-content: end;
  text-decoration: none;
}

.titre-footer {
  color: black;
}

.modal-content {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 0px;
  text-align: center;
}

.modal-body {
  padding: 5px 5px 0px 5px;
  position: relative;
  flex: 1 1 auto;
}

.modal-content video {
  width: 100%;
  height: auto;
  border-radius: inherit;
}

.video-bloc-center {
  width: 500px !important;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0rem 1rem !important;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

#modalPack .modal-header {
  margin-top: 20px;
  display: flow;
  margin-bottom: 10px;
  border-bottom: none;
}
#modalPack .modal-footer {
  display: block;
  border-top: none;
}
#modalPack .form-newsletter {
  margin-bottom: 0px !important;
}
.input-error {
  border-color: red;
}
.alerte_info_pack_email, .alerte_info_pack_societe, .alerte_info_pack_conditions {
  font-size: 12px;
  color: red;
  font-weight: 700;
  display: none;
}
h5.text-center.titre-footer {
  font-family: Quicksand-Medium;
  color: #333333 !important;
}

input.form-check-input.cheeck-condition {
  box-shadow: inset 0px 3px 6px #00000029;
  border: 1px solid #DCDCDC;
  border-radius: 4px;
  width: 39px;
  height: 20px;
  margin-top: 3px;
  margin-left: 13px;
}

.wp .titre-accordien {

  display: none;
}

strong.titre-adr-footer {
  font-family: Quicksand-Medium;
}
/****************modal-pack***********/
.modal-pack .modal-content:after {
  content: "";
  position: absolute;
  left: -20px;
  width: 100%;
  height: calc(100% - 50px);
  bottom: -20px;
  border-radius: 33px;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0.227328431372549) 0%, rgba(255,255,255,0) 100%);
  z-index: -1;
}
.modal-pack .modal-content{
  border-radius: 33px;
}
.modal-pack .modal-title{
  font-size: 22px !important;
  font-family: Quicksand-Bold;
  color: #FD5B2F !important;
  margin-bottom: 10px;
}
.modal-pack .modal-body{
  padding: 5px 15px 0 15px ;
}
.modal-pack .modal-body p{
  font-size: 18px !important;
  font-family: Quicksand-Regular;
  color: #000000 !important;
}
.modal-pack .modal-dialog{
max-width: 700px !important;
}
.form_pack {
  width: 95%;
  margin: 0 auto;
}
.agree-policy{
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.form-check{
  padding-left: 0;
  display: flex;
  align-items: flex-start;
}
.agree-policy .form-check-input{
  margin-left: 0 !important;
  margin-top: 0 !important;
}
.modal-pack .modal-body .form-newsletter input.mail {
  /*border: none !important;*/
  color: #999999 !important;
  font-size: 14px;
  background: url(../../public/images/footer/contact/mail.svg) no-repeat left;
  background-size: 20px;
  background-position: 15px;
  border-radius: 10px;
  padding: 15px 15px 15px 50px;
}
.modal-pack .modal-body .form-newsletter input.societe {
  /*border: none !important;*/
  color: #999999 !important;
  font-size: 14px;
  background: url("../../public/images/footer/contact/entreprise.svg") no-repeat left;
  background-size: 13px;
  background-position: 15px;
  border-radius: 10px;
  padding: 15px 15px 15px 50px;
}
.textcond {
  font-size: 12px;
  font-weight: 700;
  padding-left: 10px;
}
#btnCheckModalPack {
  float: unset !important;
  box-shadow: inset 0 0 5px #a5a5a5;
  border: none;
}
.modal-pack .modal-body .form-newsletter a {
  font-size: 12px;
  color: #000;
  font-family: Quicksand-Medium;
}
.modal-pack .modal-footer a{
  display: none;
}
.modal-pack .modal-footer .submit{
    color: #fff !important;
    display: block;
    width: 151px;
    height: 48px;
    font-size: 14px;
    background: #FD5B2F !important;
    border-radius: 30px;
}
/***************style titre & parg ************/
h1 {
  padding-bottom: 15px;
  font-family: Quicksand-Medium;
  font-size: 40px;
}

h1 strong {
  padding-bottom: 15px;
  font-family: Quicksand-Medium;
}

h3 {
  font-family: Quicksand-Medium;
}


h6 {
  font-family: Quicksand-Regular;
  letter-spacing: 0px;
  color: #0B014A;
  font-weight: bold;
  font-size: 16px;

}

b {
  font-family: Quicksand-Medium;
  font-weight: bold;
}

p {
  font-family: Quicksand-Regular;
  font-size: 18px;
  font-weight: 100;
  color: #000000;

}

a {
  font-family: Quicksand-Regular;
  font-size: 18px;
  color: #000;
}

strong {
  font-weight: 700;
}

.section-reactivite h1 {
  color: #00395C;
  font-size: 35px;
  font-family: Quicksand-Regular;

}

.section-reactivite h1 strong {
  color: #407BFF;
}

.section-box-we h4 {
  font-family: Quicksand-Medium;
  color: #ED5F11;
  font-size: 35px;
  text-align: center;
}

/***********mention-légales*************/
.section-mention-legales {
  padding: 127px 7px;

}

.section-mention-legales h1 {
  padding-bottom: 15px;
  font-family: Quicksand-Medium;
  font-size: 35px;
}

/***********Condition général d'utilisation*************/
.section-condition-general {
  padding: 127px 7px;

}

.section-condition-general h1 {
  padding-bottom: 15px;
  font-family: Quicksand-Medium;
  font-size: 35px;
}

/***************slider-we section**************/
.slider-we-section {
  background-image: url(../../public/images/wave_background\ slider.svg);
  padding: 80px 7px;
  /* background-position: 0px 130%; */
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  margin: 50px 0;
  /* animation-name: background-animation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}

.slider-we-section h1 strong {
  color: #ED5F11;
  font-size: 40px;
}

.slider-we-section h1 {
  padding-top: 20%;
}


.heurs-travail {
  text-decoration: none;
}

.separator-slider-we-section {
  height: 583px;
  border-radius: 100px;
  position: relative;
  /* transform: translate(50px, 0px);
  animation-name: fade-left;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}

.separator-slider-we-section:before {
  background-image: url('../../public/images/WE/logo_we.svg');
  content: ' ';
  position: absolute;
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  -wtsbkit-box-sizing: border-box;
  box-sizing: border-box;
  /* transform: translate(-50%, -50%) rotate(300deg); */
  transform: translate(-50%, -50%) rotate(360deg);
  top: 50%;
  width: 321px;
  height: 321px;
  left: 0;
  /* animation-name: rotation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}

.btn-mobile-wp {
  display: none;
}


.slider-we-section h1 strong p {
  color: #ED5F11;
  font-size: 35px;
}

.slider-we-section h1 p {
  padding-bottom: 15px;
  font-family: Quicksand-Medium;
  color: #0B014A;
  font-size: 35px;

}

/***************slider-wp section**************/
.slider-wp-section {
  background-image: url(../../public/images/wave_background\ slider.svg);
  padding: 80px 7px;
  /* background-position: 0px 130%; */
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  margin: 50px 0;
  /* animation-name: background-animation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}

/* .box-downsection-reactivite{
  animation-name: text-animation;
    animation-duration: 2.5s;
    animation-fill-mode: forwards;
    transform: translate(0px, 40px);
} */
.heurs-travail {
  text-decoration: none;
}

.separator-slider-wp-section {
  height: 583px;
  border-radius: 100px;
  position: relative;
  /* transform: translate(50px, 0px);
  animation-name: fade-left;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}

.separator-slider-wp-section:before {
  background-image: url('../../public/images/WP/logo_wp-1.svg');
  content: ' ';
  position: absolute;
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* transform: translate(-50%, -50%) rotate(300deg); */
  transform: translate(-50%, -50%) rotate(360deg);
  top: 50%;
  width: 321px;
  height: 321px;
  left: 0;
  /* animation-name: rotation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}

/*************section-wfo**********************/
.slider-wfo-section {
  background-image: url(../../public/images/wave_background\ slider.svg);
  padding: 80px 7px;
  /* background-position: 0px 130%; */
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  margin: 50px 0;
  /* animation-name: background-animation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}


.separator-slider-wfo-section {
  height: 583px;
  border-radius: 100px;
  position: relative;
  /* transform: translate(50px, 0px);
  animation-name: fade-left;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}

.separator-slider-wfo-section:before {
  background-image: url('../../public/images/WFO/logo_wfo.svg');
  content: ' ';
  position: absolute;
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* transform: translate(-50%, -50%) rotate(300deg); */
  transform: translate(-50%, -50%) rotate(360deg);
  top: 50%;
  width: 321px;
  height: 321px;
  left: 0;
  /* animation-name: rotation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}

.col-md-6.align-self-center.box-downcontainer.bloc-multi {
  margin-top: 7em;
}

.container.bloc-multi h3 {
  font-family: Quicksand-Bold;
  font-size: 22px;
  color: #16254F;
}

.container.bloc-multi h3 strong {
  font-family: Quicksand-Bold;
  letter-spacing: 0px;
  color: #C9428A;

}

.section-mobile-wp {
  display: none;
}

/***************slider-wts section**************/
.slider-wts-section {
  background-image: url(../../public/images/wave_background\ slider.svg);
  padding: 80px 7px;
  /* background-position: 0px 130%; */
  background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    margin: 50px 0;
    /* animation-name: background-animation;
    animation-duration: 2.5s;
    animation-fill-mode: forwards; */
}

.slider-wts-section h1 strong {
  color: #68C5D4;
  font-size: 32px;
}
.slider-wts-section h1{
  font-size: 32px;
}

.separator-slider-wts-section {
  height: 583px;
  border-radius: 100px;
  position: relative;
  /* transform: translate(50px, 0px);
  animation-name: fade-left;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}

.separator-slider-wts-section:before {
  background-image: url('../../public/images/WTS/rond_wh.svg');
  content: ' ';
  position: absolute;
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* transform: translate(-50%, -50%) rotate(300deg); */
  transform: translate(-50%, -50%) rotate(360deg);
  top: 50%;
  width: 321px;
  height: 321px;
  left: 0;
  /* animation-name: rotation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}
.section-solution {
  background-image: url(../../public/images/wave_background\ slider.svg);
  padding: 0px 0% 4% 0%;
  background-position: center 82%;
  background-repeat: no-repeat;
  background-size: 100%;
  height: auto;
  position: relative;
}

.section-solution-ref {
  background-image: url(../../public/images/wave_background\ slider.svg);
  padding: 11% 0% 6% 0%;
  background-position: center 82%;
  background-repeat: no-repeat;
  background-size: 100%;
  height: auto;
  position: relative;
}

.section-solution .owl-carousel .owl-stage {
  margin: 0 auto;
}

.slider-wts-section .btn-play-vd-slider {
  color: #fff !important;
  display: block;
  width: 150px;
  height: 54px;
  font-size: 10px;
  background: transparent linear-gradient(180deg, #3CB1C4 0%, #2591A3 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border-color: none;
  opacity: 1;
  border-color: transparent;
  display: flex;
  align-items: center;
  padding: 10px;
}

.slider-we-section .btn-play-vd-slider {
  color: #fff !important;
  display: block;
  width: 150px;
  height: 54px;
  font-size: 10px;
  background: transparent linear-gradient(180deg, #E07550 0%, #B75094 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border-color: none;
  opacity: 1;
  border-color: transparent;
  display: flex;
  align-items: center;
  padding: 10px;
}

.slider-wp-section .btn-play-vd-slider {
  color: #fff !important;
  display: block;
  width: 150px;
  height: 54px;
  font-size: 10px;
  background: transparent linear-gradient(180deg, #417CFF 0%, #417CFF 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border-color: none;
  opacity: 1;
  border-color: transparent;
  display: flex;
  align-items: center;
  padding: 10px;
}

.slider-wfo-section .btn-play-vd-slider {
  color: #fff !important;
  display: block;
  width: 150px;
  height: 54px;
  font-size: 10px;
  background: transparent linear-gradient(180deg, #C9428A 0%, #A62C6D 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border-color: none;
  opacity: 1;
  border-color: transparent;
  display: flex;
  align-items: center;
  padding: 10px;

}

/****************section-cartographie*************/
.section-cartographie {
  margin-bottom: 20px;
}

.section-cartographie h2 {
  font-size: 30px;
  color: #000000;
  padding-bottom: 2%;
  text-align: left;
  font-family: Quicksand-Medium;
  letter-spacing: 0px;
}

.section-cartographie h2 strong {
  font-size: 30px;
  color: #C9428A;
  padding-bottom: 15px;
  text-align: left;
  letter-spacing: 0px;
  font-family: Quicksand-Medium;
}

.section-cartographie .row {
  align-items: self-start;
}

.section-expertice .img-mobile.wp .titre-accordien-1 h3 strong {
  display: none !important;

}

.img-mobile.wp {
  text-align: center;
  /* transform: translate(-25px, 0);
  animation-name: left-right;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}

.section-cartographie .box-content::before {
  content: "";
  border-bottom: 3px solid #C9428A;
  width: 30px;
  position: absolute;
  top: 50px;
  left: 35px;
  transform: translate(-25px, -25px);
}

.section-cartographie .box-content {
  position: relative;
}

.box-content {
  color: #000000;

}

.section-cartographie .box-content p {
  padding-left: 35px;
}

.section-outil-technologique-1 .box-content p {
  font-size: 14px;
}

.section-outil-technologique-1 .box-content p strong {
  font-size: 14px;
}



.prg-cartographie-1::before {
  content: "—";
  color: #C9428A;
  font-weight: 900;

}

.prg-cartographie-2::before {
  content: "—";
  color: #C9428A;
  font-weight: 900;

}

.owl-carousel-wfo {
  display: flex;
}

.owl-carousel-wfo-2 {
  display: flex;
}

/******************section-with-vedio**************/
.imageOne {
  z-index: 0;
}

.imageTwo {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-img {
  background-image: url(../../public/images/WFO/background\ video.svg);
  background-position: top center;
  background-repeat: no-repeat;
  border-radius: 80px;
  padding: 40px;
  position: relative;


}


.carrousel-notre-solution .owl-carousel .owl-nav button.owl-prev,
.carrousel-notre-solution .owl-carousel .owl-nav button.owl-next,
.carrousel-notre-solution .owl-carousel button.owl-dot {
  color: gray;
  font-size: 60px;
  outline: none;

}
.section-solution-ref .carrousel-notre-solution .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #ED5F11;
}
.section-solution-ref h1{
  padding-bottom: 50px;

}
.carrousel-notre-solution .owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  left: 0;
  top: 12%;
}

.carrousel-notre-solution .owl-carousel .owl-nav button.owl-next {
  position: absolute;
  right: 0;
  top: 12%;

}

.section-with-background {
  padding: 90px;
  background-size: 66%;
  border-radius: 80px;
  background: linear-gradient(#F7EDF4, #FFFFFF00);
  margin: 0 0;

}

.section-with-background-data {
  padding: 90px;
  background-size: 66%;
  border-radius: 80px;
  background: linear-gradient(#F7EDF4, #FFFFFF00);
  margin: 0 0;

}

/* .section-solution-en-situation {
  margin-bottom: 60px;
} */

.section-solution-en-situation img {
  height: auto;
  padding-bottom: 4%;
}

.section-solution-en-situation .parg-inscription {
  text-align: center !important;
  padding-left: 20px;
  padding-right: 20px;
}

.section-solution-en-situation .text-img-opt .col-md-7 {
  background: linear-gradient(#F7EDF4, #FFFFFF00);
  border-radius: 100px;
  margin-top: 70px;
}

.section-solution-en-situation .text-img-opt img {
  transform: translate(-13px, -52px);
}

.custom-line-bloc2 .carrousel-box .owl-carousel.owl-drag .owl-item:nth-child(2) {
  padding-top: 10%;
}

.custom-line-bloc2 .carrousel-box .owl-carousel.owl-drag .owl-item:nth-child(3) {
  padding-top: 15%;
}

/***********section-experience************/
.section-experience-content::before {
  content: " ";
  background-image: url(../../public/image-front/image-benefice.svg);
  width: 150px;
  height: 100px;
  position: absolute;
  z-index: 2;
  right: 0;
  top: -15px;
  /* transform: translate(0px, -40px); */
}

/* span.titre-outil-tech-wts{
  animation-name: text-animation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  transform: translate(0px, 40px);
} */
.is_header {
  /*margin-top: 109px;*/
  background: transparent !important;
}

.is_header_content::before {
  background-image: none !important;
}
.section-experience-content {
  background-size: 66%;
  border-radius: 80px;
  background: linear-gradient(#E9F5F5, #FFFFFF00);
  position: relative;
  padding: 5% 30px;
}

.is_header_content { 
  background: transparent !important; 
}

.owl-item-content {
  position: absolute;
  top: 5px;
  width: 100%;
  height: 96%;
  left: 40%;
  border-radius: 12px;
  padding: 20px;
  color: #000;
  display: grid;
  align-items: top;
}

.section-experience .owl-carousel .owl-nav.disabled,
.section-experience .owl-carousel .owl-dots.disabled,
.is_header .owl-carousel .owl-nav.disabled,
.is_header .owl-carousel .owl-dots.disabled
{
  display: block !important;
}

.section-experience .owl-carousel .owl-nav button.owl-prev,
.section-experience .owl-carousel .owl-nav button.owl-next,
.section-experience .owl-carousel button.owl-dot {
  color: #4BBDCF;
  font-size: 60px;
}

.is_header .owl-carousel .owl-nav button.owl-prev,
.is_header .owl-carousel .owl-nav button.owl-next,
.is_header .owl-carousel button.owl-dot {
  color: #E4600E;
  font-size: 60px;
}
.slider-agence-section .owl-theme .owl-dots .owl-dot.active span, .slider-agence-section .owl-theme .owl-dots .owl-dot:hover span{
  background: #E4600E;
}
.slider-agence-section .owl-theme .owl-dots .owl-dot span{
  background: #F2B48D;
}
.section-experience button.owl-next, .is_header button.owl-next {
  position: absolute;
  top: 30%;
  /* right: -18%; */
  right: -11%;
}


.section-experience button.owl-prev, .is_header button.owl-prev  {
  position: absolute;
  top: 30%;
  /* left: -16%; */
  left: -9%;
}

.section-experience button:focus, .is_header button:focus  {
  outline: 1px dotted;
  outline: none;
}

.section-experience .owl-theme .owl-dots .owl-dot.active span,
.section-experience .owl-theme .owl-dots .owl-dot:hover span {
  background: #4BBDCF;
}

.section-experience .owl-theme .owl-dots .owl-dot span {
  background: #A6DFE8;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #86979100 !important;
}


.section-experience-content h1 {
  color: #4DBDCF;
  font-family: Quicksand-Medium;
  font-size: 35px;
}

.img-box span {
  font-size: 75px;
  color: #4DBDCF;
  font-family: Quicksand-Regular;
  position: absolute;
  bottom: 0;
  left: 66%;
}

.is_header .img-box span  {
  color: #E4600E;
}

.img-box span sup {
  font-size: 25px;
  color: #E4600E;
  font-family: Quicksand-Regular;
  top: -1.5em;
}

.section-experience-content .img-box {
  height: 390px;
  position: relative;
  border-radius: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  width: 100% !important;
}

.section-experience-content h4 {
  font-family: Quicksand-Regular;
  letter-spacing: 0px;
  color: #68C5D4;
  font-size: 25px;
  font-weight: 500;

}

.section-experience-content h2 {
  font-family: Quicksand-Medium;
  letter-spacing: 0px;
  color: #4DBDCF;
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 7%;

}

.section-experience h2 {
  font-family: Quicksand-Medium;
  color: #1E3250;
  font-size: 35px;
}

.section-experience h2 strong {
  font-family: Quicksand-Medium;
  color: #4DBDCF;
  font-size: 35px;
}

.bloc-margin-top p {
  margin-top: -3%;
}

/************titre & parg specifique ***********/

.section-pedagogier h1 strong {
  font-size: 35px;
  color: #417CFF;
  padding-bottom: 2%;
  text-align: left;
  font-family: Quicksand-SemiBold;
  letter-spacing: 0px;
  text-align: center;
}

.section-pedagogier h1 {
  font-size: 35px;
  color: #112F6D;
  padding-bottom: 15px;
  text-align: left;
  letter-spacing: 0px;
  font-family: Quicksand-Medium;
  text-align: center;

}



.slider-wp-section h1 {
  padding-bottom: 15px;
  font-family: Quicksand-Medium;
  color: #112F6D;
  font-size: 35px;

}

.slider-wfo-section h1 {
  color: #000000;
  font-size: 40px;
}

.slider-wfo-section h1 strong {
  color: #C9428A;
  font-size: 40px;
}

.slider-wfo-section h2 {
  color: #000000;
  font-size: 35px;
}

.slider-wfo-section h2 strong {
  color: #C9428A;
  font-size: 40px;
}

.section-with-background h2 {
  color: #000000;
  font-size: 30px;
  font-family: Quicksand-Medium;

}

.section-with-background h2 strong {
  color: #C9428A;
  font-size: 30px;
  font-family: Quicksand-Medium;
  font-weight: 700;

}

.section-with-background-data h2 {
  color: #000000;
  font-size: 30px;
  font-family: Quicksand-Medium;

}

.section-with-background-data h2 strong {
  color: #C9428A;
  font-size: 30px;
  font-family: Quicksand-Medium;
  font-weight: 700;

}


.section-client h1 {
  font-size: 35px;
  color: #1E3250;

}

.section-client h1 strong {
  color: #3D76F3;
}

/******************section-mobile**************/
.section-mobile {
  padding: 5% 60px;
  margin: 15px 0;
  background-size: 66%;
  border-radius: 80px;
  background: linear-gradient(#F8E7F7, #FFFFFF00);

}

.prg-mobile {
  width: 563px;
  height: 184px;
}


.mobile-imgs {
  display: flex;
  align-items: center;
  flex-direction: column;

}

.mobile-img-2 {
  display: grid;
}

.section-mobile h1 {
  font-size: 35px;
  color: #0B014A;
  padding-bottom: 2%;
  font-family: Quicksand-Regular;
}

.section-mobile h1 strong {
  font-size: 35px;
  padding-bottom: 15px;
  text-align: left;
  letter-spacing: 0px;
  font-family: Quicksand-Medium;
  color: #ED5F11;
}


.section-solution-en-situation h2 {
  font-size: 30px !important;
  color: #000000 !important;
  font-family: Quicksand-Bold;
  padding-bottom: 1%;
}

.section-solution-en-situation h2 strong {
  color: #C9428A;

}

.section-with-background .box-downsection-with-background.visible-mobile {
  margin-left: 8.333333%;
}

.section-with-background-data .box-downsection-with-background.visible-mobile {
  margin-left: 8.333333%;
}

/********************.custom-line *****************/
.custom-line {
  margin-bottom: 60px;
}

.owl-carousel-wfo {
  display: flex;
}

.owl-carousel-wfo-2 {
  display: flex;
}

.section-solution-en-situation .text-img-opt {
  justify-content: center;
}

.section-solution-en-situation .text-img-opt .col-md-8 {
  margin-top: 50px;
}

/******************section-avantage**************/
.section-avantage-content {
  padding: 5% 0px;
  background-size: 66%;
  border-radius: 80px;
  background: linear-gradient(#FFF6F1, #FFFFFF00);
}
.avantage-box {
  display: flex;
  align-items: start;
  padding-bottom: 20px;
}

.avantage-box img {
  padding-right: 15px;
}

.prg-nos-solution {
  font-size: 22px;
  color: #000000;
}

.section-nos-solution.m-5 h2 {
  margin-bottom: 26px;
  font-family: Quicksand-Medium;

}

.titre-nos-solution {
  margin-bottom: 26px !important;
}

.text-box-avantage {
  padding: 1%;
}

.text-box-avantage h3 {
  padding-bottom: 15px;
  font-size: 22px !important;
  color: #E96920;
  font-family: Quicksand-Medium;

}

.img-card-avantage {
  margin-bottom: 16%;

}

/**************section-benefices**********/
.section-benefices {

  background-image: url(../../public/images/WTS/wave_background\ benefice.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

.prg-benefices {
  font-size: 16px;
}

.img-benefices {
  transform: translate(2%, -51%);
  padding: -2%;
  margin-top: 13%;
}

.titre-benefices {
  font-size: 30px;
  text-align: center;
  font: normal normal normal 30px/22px Quicksand;
  letter-spacing: 0px;
  color: #3CB1C4;
  opacity: 0.51;
}

.card-benefices-client,
.card-benefices-entreprise {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* animation-name: text-animation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  transform: translate(0px, 40px); */
}

.card-benefices-client {
  padding-top: 14% !important;

}

.card-benefices-client .card-body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  height: 340px;
  width: 365px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #7E76F91A;
  border-radius: 44px;
  opacity: 1;
  padding: 7%;
  justify-content: center;
}

.card-benefices-client img {
  transform: translate(0px, 50px);
  z-index: 1;
}

.card-benefices-client .card-body h5 {
  flex: 1 0 auto;
  padding-top: 60px;
  position: relative;
}

.card-benefices-client .card-body h5:before {
  content: " ";
  background-image: url(../../public/image-front/trait.svg);
  font-family: Quicksand-Regular;
  height: 2px;
  width: 60px;
  position: absolute;
  top: 65%;
  left: -15%;
  transform: translate(-50%, -50%);
}

.section-benefices .row {
  justify-content: center !important;
}

.card-benefices-entreprise .card-body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  height: 340px;
  width: 365px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #7E76F91A;
  border-radius: 44px;
  padding: 7%;
  justify-content: center;
}

.section-benefices .card-img,
.section-benefices .card-img-bottom,
.section-benefices .card-img-top {
  width: auto !important;
}

.img-benefice-entreprise {
  transform: translate(0px, 4px);
}

.card-benefices-entreprise img {
  transform: translate(0px, 50px);
  z-index: 1;
}

.card-benefices-entreprise h5 {
  font-size: 20px;
  color: #22436D;
  font-family: Quicksand-Regular;
  flex: 1 0 auto;
  padding-top: 60px;
  position: relative;
}

.card-benefices-entreprise h5:before {
  content: " ";
  background-image: url(../../public/image-front/trait.svg);
  font-family: Quicksand-Regular;
  height: 2px;
  width: 60px;
  position: absolute;
  top: 65%;
  left: -15%;
  transform: translate(-50%, -50%);
}

.card-benefices-entreprise h5 strong {
  font-family: Quicksand-Bold;
}

.card-benefices-entreprise p::before {
  font-size: 16px;
  content: "+";
  font-family: Quicksand-Bold;
  color: #275B80;
  padding-right: 10px;
}

.card-benefices-client h5 strong {
  font-size: 20px;
  color: #3CB1C4;

}

.section-benefices p {
  font-size: 16px;
  font-family: Quicksand-Regular;

}

.card-benefices-client p::before {
  font-size: 16px;
  content: "+";
  font-family: Quicksand-Bold;
  color: #4BBDCF;
  padding-right: 10px;
}

.section-benefices h2 strong {
  font-size: 35px;
  color: #3CB1C4;
}

.section-benefices h2 {
  color: #868DA5;
  font-size: 30px;
  font-family: Quicksand-Regular;
}


.card-benefices-client p {
  font-size: 16px;
  color: #22436D;

}

.card-benefices-client p strong {
  font-size: 16px;
  color: #3CB1C4;

}

.img-benefice-client {
  transform: translate(0px, -2px);
}

.sous-titre-benefices {
  color: #868DA5;
  font-size: 30px;
  font-family: Quicksand-Regular;
}

/*******************section-client*****************/
.section-client {
  padding: 5% 60px 0;
  position: relative;
  /* animation-name: text-animation;
    animation-duration: 2.5s;
    animation-fill-mode: forwards;
    transform: translate(0px, 40px); */
}

.section-client .carrousel-box-wp {
  margin-top: 5%;
}
.carrousel-box-wp .owl-dots{
  margin-left: 20px;
}
.section-client .carrousel-box-wp .row {
  justify-content: space-around;
  display: flex !important;
}

.section-client .card {
  border-radius: 10px;
  background: transparent 0% 0% no-repeat padding-box;
  border: none;
}

.client-box {
  padding-top: 40px;
  padding-bottom: 40px;
}

.contact-box {
  padding-top: 40px;
  padding-bottom: 40px
}


.section-client i {
  color: #B2B2B2;
  font-size: 20px;
  padding-right: 8px;
}

.section-client .card {
  border-radius: 10px;
  background: transparent 0% 0% no-repeat padding-box;
  border: none;

}

.card-text {
  font-size: 12px;
  font-family: Quicksand-Regular;

}

.card-title-client {
  font-size: 22px;
  font-family: Quicksand-SemiBold;

}

.section-client a {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ccc;
  background: #1C72BD;
  text-align: center;
  text-decoration: none;
  color: #fff !important;
  font-size: 13px;
  font-weight: bold;
  border-radius: 97px;
}

.section-client h5 {
  font-family: Quicksand-Regular;
  letter-spacing: 0px;
  color: #000000;
  font-weight: bold;
  font-size: 22px;
}

.section-client h6 {
  font-family: Quicksand-Regular;
  letter-spacing: 0px;
  color: #1A1F4F;
  font-weight: bold;
  font-size: 16px;
}


.section-client h5::before {
  content: "—";
  color: #1A1F4F;
  font-weight: 900;
  padding-right: 10px;
}

h4 {
  font-family: Quicksand-Bold;
  color: #C9428A;
  font-size: 25px;
}

.section-solution h1 {
  color: #1E3250;
  margin-bottom: 50px;
}

.section-solution.wfo strong {
  color: #3CB1C4 !important;
}

.notre-solution h1 {
  font-size: 30px;
  color: #000000;

}

.notre-solution h1 strong {
  color: #C9428A !important;
}

.section-solution-en-situation h1 {
  color: #1E3250;

}

.section-solution.wfo h1 {
  color: #1E3250;
  padding-bottom: 50px;

}

.section-solution.wfo strong {
  color: #C9428A !important;
}

.section-solution.wts h1 {
  color: #1E3250;
  padding-bottom: 50px;
  padding-top: 50px;
}

.section-solution.wts h1 strong {
  color: #68C5D4;
  padding-bottom: 50px;

}

.section-solution.we h1 {
  color: #1E3250;
}

.section-solution.wp h1 strong {
  color: #417CFF;
}
.section-solution.wp h1 {
  color: #1E3250;
}

.section-solution.wp h1 strong {
  color: #ED5F11;
}
.img-box {
  height: 237px;
  width: auto !important;
}

.box-content p {
  text-align: center;
  color: #000;
}

.custom-line-bloc2.owl-carousel.owl-carousel-wfo-2.owl-theme .carrousel-box .box-content p::before {
  content: "";
  border-bottom: 2px solid #C9428A;
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}

/******************section-expertice**************/
.section-expertice {
  padding: 3% 2%;
  background-size: 66%;
  border-radius: 80px;
  background: transparent linear-gradient(0deg, #F3F6FF 0%, #EFF5FF 100%) 0% 0% no-repeat padding-box;
  margin: 50px 0;
}


.expertice-imgs {
  display: flex;
  align-items: center;
  width: 356px;
  height: 349px;
}

.expertice-img-2 {
  display: grid;
}

.section-expertice h3 ::before {
  content: "—";
  font-weight: 900;
  color: #407BFF;
  padding-right: 15px;
}

.section-expertice h3 {
  font-family: Quicksand-SemiBold;
  color: #407BFF;
  font-size: 29px;
}

.section-accompagnement h1 {
  font-size: 35px;
  color: #112F6D;
  padding-bottom: 15px;
  text-align: left;
  letter-spacing: 0px;
  font-family: Quicksand-Medium;
}

.section-accompagnement img {
  margin: auto;
}

.section-accompagnement h1 strong {
  font-size: 40px;
  color: #417CFF;
  padding-bottom: 2%;
  text-align: left;
  font-family: Quicksand-SemiBold;
  letter-spacing: 0px;
}

.slider-wp-section h1 strong {
  color: #417CFF;
}

.section-inscription h2 {
  font-size: 35px;
  color: #ED5F11;
  padding-bottom: 15px;
  text-align: left;
  letter-spacing: 0px;
  font-family: Quicksand-Medium;
  text-align: center;
}

/**************section-inscription**********/
.section-inscription {
  background-image: url(../../public/images/wave_background\ slider.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-position: right bottom;
  /* animation-name: text-animation;
    animation-duration: 2.5s;
    animation-fill-mode: forwards;
    transform: translate(0px, 40px); */
}

.parg-inscription {
  text-align: center !important;
}


/****************section-box-we***************/
.section-box-we {
  margin: 5% 0 5% 0;
  border-radius: 80px;
}

.box-content {
  padding: 17px;
}

.section-box-we p {
  text-align: center;
}

/****************section-bien_plus_encore***************/

.section-bien_plus_encore {
  background-image: url(../../public/images/WE/bg.svg);
  padding: 4% 60px;
  background-size: cover;
  border-radius: 80px;
  background-position: center center;
  background-repeat: no-repeat;
}

.section-bien_plus_encore h1 {
  font-family: Quicksand-Regular;
  color: #FFFFFF;
  font-size: 35px;
}

.section-bien_plus_encore p {
  color: white;
}

.bloc-text-bien_plus_encore h3 {
  font-family: Quicksand-Regular;
  color: #FFFFFF;
  padding-left: 5%;

}

.titre-bien_plus_encore {
  color: #fff;
}

.section-client p {
  font-size: 12px;
  font-family: Quicksand-Regular;

}

/******************section-reactivite**************/
.section-reactivite {
  padding: 5% 25px;
  background-size: 66%;
  border-radius: 80px;
  background: linear-gradient(#E6F0FD, #FFFFFF00);
}


.reactivite-imgs {
  width: 454px;
  height: 296px;
}

.prg-reactivite {
  width: 543px;
  height: 139px;
}

.titre-outil-tech-wts h2 {
  font-size: 35px;
  color: #1E3250;
  padding-bottom: 15px;
  text-align: left;
  font-family: Quicksand-Regular;
  letter-spacing: 0px;


}

.titre-outil-tech-wts h2 strong {
  font-size: 35px;
  color: #68C5D4;
  padding-bottom: 15px;
  text-align: left;
  letter-spacing: 0px;
  font-family: Quicksand-Medium;

}

.titre-expertice-wp h2 {
  font-size: 35px;
  color: #112F6D;
  padding-bottom: 15px;
  text-align: left;
  font-family: Quicksand-Medium;
  padding-top: 4%;
}

.titre-expertice-wp strong {
  font-size: 35px;
  color: #417CFF;
  padding-bottom: 2%;
  text-align: left;
  font-family: Quicksand-SemiBold;
  letter-spacing: 0px;
}

/******************asection-accompagnement***************************/
.section-accompagnement {
  background-image: url(../../public/images/wave_background\ slider.svg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 60px;

}
.section-accompagnement img{
  width: 60%;
  margin: 0 auto;
}

/***************slider-agence section**************/
.slider-agence-section {
  background-image: url(../../public/images/WFO/wave_background_slider.svg) !important;
  padding: 80px 7px;
  background-position: center bottom !important;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  position: relative;
  margin: 50px 0;
  /* animation-name: background-animation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
  margin-bottom: -51px;
}



.separator-slider-agence-section {
  height: 583px;
  border-radius: 100px;
  position: relative;
  text-align: right;
  /* transform: translate(50px, 0px);
  animation-name: fade-left;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}

.titre-slider-agence {
  width: 387px;
  height: 100px;
}

.separator-slider-agence-section img {
  height: 583px;
}



.separator-slider-agence-section:before {
  background-image: url('../../public/images/agence/logo_netisse.svg');
  content: ' ';
  position: absolute;
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* transform: translate(-50%, -50%) rotate(300deg); */
  transform: translate(-50%, -50%) rotate(360deg);
  top: 50%;
  width: 321px;
  height: 321px;
  left: 0;
  /* animation-name: rotation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards; */
}



.slider-agence-section h1 {
  font-size: 40px;
  color: #000000;
  padding-bottom: 30px;
  text-align: left;
  letter-spacing: 0px;
  font-family: Quicksand-Medium;
}

.slider-agence-section h1 strong {
  color: #E96920;
  font-family: Quicksand-Medium;

}

/******************section-avantage**************/

.avantage-box {
  display: flex;
  align-items: start;
  padding-bottom: 20px;
}

.avantage-box img {
  padding-right: 15px;
}

.prg-nos-solution {
  font-size: 22px;
  color: #000000;
}

.section-nos-solution.m-5 {
  padding-bottom: 1%;
}

.section-nos-solution.m-5 .col-md-12 {
  width: 60% !important;
}

.titre-nos-solution {
  margin-bottom: 26px !important;
}

.section-avantage h2 {
  font-family: Quicksand-Medium;
  color: #000000;
  margin-bottom: 2%;

}

.dropdown-menu {
  border: none !important;
}

/******************box-wts*************/
.card-nos-solution {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  height: 385px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 60px;
  padding: 7%;
  box-shadow: 0px 10px 20px #7E76F91A;
  margin-bottom: 25px;
  margin-right: 40px;
}

.card-nos-solution h5 a {
  text-decoration: none;
  color: #000000;
  font-family: Quicksand-Bold;
  font-size: 20px;

}

.card-nos-solution h5 a:hover {
  border: none;
}

.card-nos-solution img {
  padding: 10px 10px 25px;
  width: 80%;
  margin: 0 auto;
}

.card-nos-solution:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translate3d(-39px, -27px, 0) scale(0.95);
  opacity: 0.33 !important;
  transition: opacity 0.3s;
  border-radius: 60px;
}

.wts::before {
  background: linear-gradient(-379deg, #3cb1c4 -73%, #F6F6F600 81%);
}
.wfo::before {
  background: linear-gradient(-379deg, #c9428a -73%, #F6F6F600 81%);
}
.wes::before{
  background: linear-gradient(-379deg, #dc3545 -73%, #F6F6F600 81%);
}
.we::before {
  background: linear-gradient(-379deg, #e95b00 -73%, #F6F6F600 81%);
}
.ws::before {
  background: linear-gradient(-379deg, #86b04b -73%, #F6F6F600 81%);
}
.wpp::before {
  background: linear-gradient(-379deg, #0655a8 -73%, #F6F6F600 81%);
}

video {
  border-radius: 60px;
}

.text-box-avantage {
  padding: 1%;
}

.text-box-avantage h3 {
  padding-bottom: 15px;
  font-size: 22px !important;
}

.img-card-avantage {
  margin-bottom: 16%;

}

.video-bloc-right {
  width: 100%;
}
.is_header .video-bloc-right{
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: fill;
  z-index: 0;
}

/* Pour le bouton "play" lui-même */
video::-webkit-media-controls-start-playback-button {
  border-radius: 50%;
  display: block;
  margin: auto;
  background-color: #3498db;
  /* Ajouter une couleur de fond */
  width: 50px;
  /* Définir la largeur */
  height: 50px;
  /* Définir la hauteur */
}

video::-moz-media-controls-play-button {
  border-radius: 50%;
  display: block;
  margin: auto;
  background-color: #3498db;
  width: 50px;
  height: 50px;
}

video::-ms-media-controls-start-playback-button {
  border-radius: 50%;
  display: block;
  margin: auto;
  background-color: #3498db;
  width: 50px;
  height: 50px;
}

/*******************section-footer*****************/
.section-footer {
  border-radius: 80px;
  padding: 0 60px 40px 60px;
  position: relative;
  margin: 50px 0;
}

.copyright {
  letter-spacing: 0px;
  color: #999999;
  font-family: Quicksand-Regular;
  margin-top: -41px;
  font-size: 14px;


}


h1.text-center.box-solution {
  padding-bottom: 50px;
}


.img-solution {
  box-shadow: 0px 3px 44px #0000000f;
  border-radius: 37px;
  opacity: 1;
  width: 159px;
  height: 156px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #fff;
}

.carrousel-notre-solution .owl-carousel .owl-item img {
  display: initial !important;
  width: 60%;

}

.carrousel-notre-solution .owl-carousel .owl-item img:hover {
  filter: grayscale(0%);
}

.owl-carousel {
  display: inherit;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}

/************box-pedagoqique*******************/
.section-pedagogier {
  background-image: url(../../public/images/wave_background\ slider.svg);
  padding: 0% 0% 10% 0%;
  background-position: center calc(103% - 20px);
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: 50px;
  animation-name: text-animation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  transform: translate(0px, 40px);
}

.section-pedagogier .img-inscription {
  transform: translate(0, 0);
}

/*******************section-contact p*****************/
.section-contact {
  padding: 2% 60px 0;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 66%;
  position: relative;
  /* animation-name: bottom-top;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  transform: translate(0, 15px); */
}

.msg-contact {
  background-color: transparent;
}

.contact-box {
  padding-top: 40px;
  padding-bottom: 40px
}

.form-background-nom {
  padding-left: 25px;
  background: url("../../public/images/footer/contact/nom.svg") no-repeat left;
  background-size: 20px;
  background-position: 15px;
  background-color: #FFFFFF;
  border-color: transparent;

}

.form-background-email {
  padding-left: 25px;
  background: url("../../public/images/footer/contact/mail.svg") no-repeat left;
  background-size: 20px;
  background-position: 15px;
  background-color: #FFFFFF;
  border-color: transparent;


}

.form-background-entreprise {
  padding-left: 25px;
  background: url("../../public/images/footer/contact/entreprise.svg") no-repeat left;
  background-size: 15px !important;
  background-position: 15px;
  background-color: #FFFFFF;
  border-color: transparent;

}

.form-background-tel {
  padding-left: 25px;
  background: url("../../public/images/footer/contact/mobile.svg") no-repeat left;
  background-size: 20px;
  background-position: 15px;
  border-color: transparent;
  background-color: #FFFFFF;
  border-color: transparent;


}

.form-background-nom-1 {
  padding-left: 25px;
  background: url("../../public/images/footer/contact/nom.svg") no-repeat left;
  background-size: 20px;
  background-position: 15px;
  border: 1px solid #E9E9E9;
}

.form-background-email-1 {
  padding-left: 25px;
  background: url("../../public/images/footer/contact/mail.svg") no-repeat left;
  background-size: 20px;
  background-position: 15px;
  border: 1px solid #E9E9E9;

}

.form-background-entreprise-1 {
  background: url("../../public/images/footer/contact/entreprise.svg") no-repeat left !important;
  background-size: 15px !important;
  background-position: 15px !important;
  border: 1px solid #E9E9E9;
}

.form-background-tel-1 {
  padding-left: 25px;
  background: url("../../public/images/footer/contact/mobile.svg") no-repeat left;
  background-size: 25px;
  background-position: 15px;
  border: 1px solid #E9E9E9;

}

.section-contact label.form-check-label.required {
  font-size: 12px;
  position: relative;
  font-weight: 400;
  padding-left: 10px;
}

.form-contact-content input {
  border-radius: 20px;
  margin-bottom: 28px;
  padding: 15px 15px 15px 45px;
  height: 62px;
}

.form-contact-content textarea {
  border-radius: 20px;
  padding: 15px 30px;
  margin-bottom: 15px;
  border: 1px solid #E9E9E9;
  height: 152px;

}

.btn-condition {
  color: #fff !important;
  display: block !important;
  width: 110px !important;
  height: 48px !important;
  font-size: 14px !important;
  background: #1C72BD 0% 0% no-repeat !important;
  border-radius: 10px !important;
}

.condition {
  top: -38px;
  position: relative;
  font-family: Quicksand-Medium;
}

.titre-contact-wp .titre-contact {
  font-size: 35px;
  color: #417CFF;
  padding-bottom: 15px;
  text-align: left;
  font-family: Quicksand-Medium;

}

.titre-contact-wp .titre-contact::before {
  content: url("../../public/images/footer/contact/arrow_contact.svg");
  padding: 10px;
}

.titre-contact-we .titre-contact {
  font-size: 35px;
  color: #ED5F11;
  padding-bottom: 15px;
  text-align: left;
  font-family: Quicksand-Medium;
}

.section-solution.we h2 strong {
  color: #ED5F11;
  padding-bottom: 22px;
  font-family: Quicksand-Medium;
  padding-top: 5%;

}

.section-solution.we h2 {
  padding-bottom: 22px;
  font-size: 35px;
  padding-top: 5%;


}

.section-inscription h1 strong {
  color: #ED5F11;
}

.titre-contact-we .titre-contact::before {
  content: url("../../public/images/footer/contact/arrow_contact_we.svg");
  padding: 10px;
}

.titre-contact-wts .titre-contact {
  font-size: 35px;
  color: #68C5D4;
  padding-bottom: 15px;
  text-align: left;
  font-family: Quicksand-Medium;
}

.titre-contact-wts .titre-contact::before {
  content: url("../../public/images/footer/contact/arrow_contact_wts.svg");
  padding: 10px;
}

.titre-contact-wfo .titre-contact {
  font-size: 35px;
  color: #C9428A;
  padding-bottom: 15px;
  text-align: left;
  font-family: Quicksand-Medium;
}

.titre-contact-wfo .titre-contact::before {
  content: url("../../public/images/footer/contact/arrow_contact_wfo.svg");
  padding: 10px;
}


/*************carrousel box***********/
.carrousel-box .owl-carousel {
  display: block;
  width: 100%;
}

.section-box {
  margin: 50px;
}

.owl-carousel .owl-item img {
  display: inline-block;
  width: 100%;
  height: auto;
}

.carrousel-experience .owl-carousel .owl-item img {
  width: 15%;
  height: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  display: inline-block;
  padding-right: 15px;
}

.carrousel-experience .box-slider {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.carrousel-experience .box-slider p {
  padding-top: 10px;
  margin-bottom: 0;
}

/******************section-win-win**************/
.section-win-win {
  padding: 3%;
  margin: 26px 0;
  background-size: 66%;
  border-radius: 80px;
  background: linear-gradient(#E9F5F5, #FFFFFF00);

}

.section-win-win img {
  border-radius: 60px;
}

.text-win-win {
  position: relative;
}

.section-win-win .text-win-win::before {
  content: url("../../public/images/WTS/hand_touch.svg");
  position: absolute;
  /* left: -50%; */
  left: -32%;
  top: -10%;
}


.notre-solution {
  padding-bottom: 20px;
}

.video-container {
  position: relative;
  margin: 0 auto;
  /* transform: translate(50px , 0); */
}



.play-pause-buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.play-pause-buttons button {
  background-color: transparent;
  border: none;
  font-size: 36px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #FFF;
  border-radius: 100%;
  width: 50px;
  height: 50px;
}

.play-pause-buttons button::after {
  content: '';
  display: inline-block;
  position: relative;
  top: -6px;
  left: 3px;
  border-style: solid;
  border-width: 10px 0 10px 20px;
  border-color: transparent transparent transparent white;
}

.play-pause-buttons button:hover {
  color: #ccc;
}

.border-primary {
  border-color: #E86012 !important;
}

.video-play {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #f4f4f47d;
  border: 1px solid #FFF;
  border-radius: 100%;
  margin: auto;
  cursor: pointer;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video-play:focus {
  outline: 0;
  border: 1px solid hsl(210, 58%, 69%);
  box-shadow: 0 0 0 3px hsla(210, 76%, 57%, 0.5);
}

.video-play::after {
  content: '';
  display: inline-block;
  position: relative;
  top: 3px;
  left: 3px;
  border-style: solid;
  border-width: 10px 0 10px 20px;
  border-color: transparent transparent transparent white;
}

.section-win-win h2 {
  font-size: 35px;
  color: #00395C;
  padding-bottom: 35px;
  text-align: left;
  font-family: Quicksand-Regular;
  letter-spacing: 0px;
}

.section-win-win h2 strong {
  font-size: 35px;
  color: #4BBDCF;
  padding-bottom: 15px;
  text-align: left;
  letter-spacing: 0px;
  font-family: Quicksand-Bold;
}

.section-win-win .box-win {
  margin-left: 8.333333%;
}


/****************section-outil-technologique***************/

.section-outil-technologique {
  background-image: url(../../public/images/WTS/background\ wts.svg);
  padding: 60px;
  background-size: cover;
  border-radius: 80px;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 50px 0;


}

.section-outil-technologique .text-bloc {
  border-left: #68C5D4 solid 1px;
  /* background-image: url(../../public/image-front/Ligne.png);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: auto; */
}

.time-ligne {
  position: relative;
  padding-left: 20px;
}

.time-ligne h4::before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: -3px;
  top: 40%;
  border: 6px solid #68C5D4;
  transform: translate(-40%, 3px);

}

.section-outil-technologique-1 {
  padding: 0 5%;
  /* animation-name: bottom-top;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  transform: translate(0, 15px); */
}




.section-outil-technologique h4 strong {
  color: #68C5D4;
}

.section-outil-technologique p {
  color: #fff;
}

.section-outil-technologique-1 strong {
  font-size: 18px;
  font-family: Quicksand-Medium;

}

.prg-outil-techno {
  font-size: 14px;
}

.bloc-text-outil-technologique-1 p {
  font-size: 14px;
  font-family: Quicksand-Regular;
  color: #fff;

}

.section-outil-technologique .text-bloc>p {
  padding-left: 30px;
  position: relative;
}

.section-outil-technologique .text-bloc>h4 {
  padding-left: 30px;
  position: relative;
}

.block-team-netisse .text-bloc>p {
  margin-bottom: 50px;
}

.block-team-netisse .orderBox1 .text-bloc p {
  margin-left: 44px !important;
}
/* .section-outil-technologique .text-bloc>h4::before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: -10px;
  top: 30px;
  border: 6px solid #68C5D4;
} */

.section-experience.container h1 {
  font-size: 35px;
  padding-bottom: 2%;
  text-align: left;
  font-family: Quicksand-SemiBold;
  letter-spacing: 0px;
  color: #112F6D;

}

.section-experience.container h1 strong {
  font-size: 35px;
  padding-bottom: 15px;
  text-align: left;
  letter-spacing: 0px;
  font-family: Quicksand-Medium;
  color: #4BBDCF;


}


/***********section-ref********************/
.section-ref {
  padding: 119px 0px 10px 0px;
  margin: 0px 50px 50px 50px;

}

/* Hide scrollbar for Chrome, Safari and Opera */
.section-ref .nav-menu-scroll::-webkit-scrollbar {
  display: none;
}

.section-ref .nav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.section-ref .nav-menu-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.section-ref .nav {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.section-ref .nav-menu-scroll {
  overflow-x: scroll;
}

.section-ref .nav {
  align-items: end;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  overflow-x: auto;
  width: max-content;
  padding-bottom: 15px;
}

.section-ref .nav a.btn.cat-all {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.section-ref a.btn.cat-all {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.btn-tab {
  width: 98px;
  height: 48px;
  border: 1px solid #707070;
  border-radius: 18px;
  background: transparent
}

.section-ref .nav-item a {
  font-size: 22px;
  font-family: Quicksand-Bold;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  transition: .4s all;
  padding: 0;
  color: #000000;
  text-align: left;
  width: 100%;
}

.section-ref .nav-item a.active {
  color: #ED5F11;
  /* ou toute autre couleur souhaitée */
}

.section-ref .nav-item a:focus {
  outline: none;
}

.active-cat {
  color: #ED5F11;
}

.section-ref .nav-item a:hover {
  color: #ED5F11;
  border: none;
}

.section-ref li.nav-item::before {
  content: '•';
  position: absolute;
  font-size: 25px;
  bottom: -7px;
  left: -16%;
  color: #ED5F11;
  transform: translateX(-50%);
}

.card.all-card-big h5 {
  font-family: Quicksand-Bold;
  letter-spacing: 0px;
  font-size: 22px;

}

/* .card.all-card-big h5::before {
  content: '•';
  position: absolute;
  font-size: 22px;
  color: #ED5F11;
} */

.card.all-card-big h5 strong {
  font-family: Quicksand-Bold;
  letter-spacing: 0px;
  font-size: 22px;
  color: #ED5F11;
}

.card.all-card-big h5 {
  font-family: Quicksand-Bold;
  letter-spacing: 0px;
  font-size: 22px;
  color: #000000;

}

.section-ref li.nav-item:first-child:before {
  content: ' ' !important;
}


.section-ref li.nav-item:last-child:before {
  content: '•';
  position: absolute;
  font-size: 25px;
  bottom: -7px;
  left: -45%;
  color: #ED5F11;
}


.section-ref li.nav-item {
  position: relative;
}

.titre-surbrillance-orange {
  color: #ED5F11 !important;
}

.cat-content {
  padding-top: 90px;
}

.tab-content {
  padding: 3% 5px;
}

.cat-all {
  font-size: 14px !important;
  color: #000000 !important;
  border: 1px solid #707070 !important;
  border-radius: 18px !important;
  height: 48px !important;
}

.card-all-agence {
  font-size: 14px !important;
  color: #000000 !important;
  border: 1px solid #707070 !important;
  border-radius: 18px !important;
  position: absolute;
  bottom: 25px;
  right: 20px;
}

a.card-all-agence {
  text-align: center !important;
}

a.cat-all {
  text-align: center !important;
}

.all-card {
  border-radius: 57px !important;
  height: auto !important;
  background: linear-gradient(#fdeee6, #FFFFFF00);
  border: none;
  margin-bottom: 20px !important;
  padding: 6%;
}

.all-card-big {
  border-radius: 57px !important;
  background: linear-gradient(#e99c7238, #fff) !important;
  border: none;
  margin-bottom: 20px !important;

}

a.btn.btn-ref-retour,
a.btn.btn-ref-details,
a.btn.btn-ref {
  color: #fff;
  border: none;
}

.btn-ref {
  width: 109px;
  height: 44px;
  background: #ED5F11 0% 0% no-repeat padding-box;
  border: none;
  color: #FFFFFF;
  border-radius: 97px;
  font-family: Quicksand-Medium;
  display: flex;
  align-items: center;
  justify-content: center;

}

.btn-ref-details {
  width: 154px;
  height: 44px;
  background: #ED5F11 0% 0% no-repeat padding-box;
  border: none;
  color: #FFFFFF;
  border-radius: 97px;
  font-family: Quicksand-Medium;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.btn-ref-retour {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  position: absolute;
  top: -20px;
  background: #ED5F11 0% 0% no-repeat padding-box;
  border: none;
  color: #FFFFFF;
  border-radius: 97px;
  font-family: Quicksand-Medium;
  width: 98px;
  height: 44px;
  text-decoration: none
}

.card-img-ref {
  position: relative;
}

.card-img-big {
  position: relative !important;
  border-radius: 35px;

}

.img1 {
  z-index: 0;
  border-radius: 35px;
  object-fit: cover;
  height: 250px;
  width: 100%;
}

.img2 {
  width: 189px;
  height: 128px;
  z-index: 1;
  top: 50%;
  left: 10%;
  border: 1px solid #FFFFFF;
  border-radius: 43px;
  box-shadow: 0px 5px 46px #00000024;
  background-color: #fff;
  transform: translate(-10%, -50%);

}

.img-cov-2 {
  width: 255;
  height: 128px;
  z-index: 1;
  top: -63px;
  left: 4%;
  border-radius: 43px;
  box-shadow: 0px 5px 46px #00000024;
  background-color: #fff;

}


.box-img {
  position: relative;
}

.card-box {
  margin-left: 6%;
}

.wlc {
  font-size: 14px;
  font-family: Quicksand-Medium;

}

.wlc-ws {
  font-size: 14px;
  font-family: Quicksand-Medium;
}

.img-content {
  width: auto;
  height: 352px;
  margin: 2%;
}

.img-cov {
  width: 1008px;
  height: 176px;
}

.puce {
  color: #ED5F11;
}

.link-brand-center {
  font-family: Quicksand-Regular;
  font-size: 18px;
  color: #0d6efd;
}

/* .body-page-we div.container:nth-child(3){
  max-width: 100%;
} */
/***********section-contact page********************/
.section-contact-page {
  padding: 155px 0px 0px 0px;
  /* animation-name: text-animation;
    animation-duration: 2.5s;
    animation-fill-mode: forwards;
    transform: translate(0px, 40px); */
}

.section-contact-page h2 strong {
  font-size: 35px;
  color: #E9600F;
  padding-bottom: 15px;
  text-align: left;
  letter-spacing: 0px;
  font-family: Quicksand-Medium;
}

.section-contact-page.form-check .form-check-input {
  height: 22px;
  width: 22px !important;
  padding: 0 !important;
}

input#contact_isAccepted {
  box-shadow: inset 0px 3px 6px #00000029;
  border: 1px solid #DCDCDC;
  border-radius: 6px;
  width: 22px !important;
  height: 22px !important;
  padding: 10px;
  margin-left: auto;
  margin-bottom: inherit !important;
}

.section-contact-page .form-contact-content textarea {
  border: none;
  border-radius: 20px;
  padding: 15px 15px 15px 15px;
  background-color: white;
  height: 160px;
}

.section-contact-page .msg-contact {
  background-color: white;
  height: 168px;
  border: none;
  border-radius: 20px;
  padding: 15px 15px 15px;
}

.section-contact-page .btn-contact {
  width: 151px;
  height: 48px;
  background: #ED5F11 0% 0% no-repeat padding-box;
  border: none;
  color: #FFFFFF;
  border-radius: 97px;
  font-family: Quicksand-Medium;
  top: 14px;
  position: relative;

}

.section-contact-page label.form-check-label.required {
  font-size: 12px;
  color: #000;
  font-family: Quicksand-Medium;
  padding-left: 10px;
}

.condition-contact {
  font-size: 12px;
  position: relative;
  font-weight: 400;
}

/***************slider-info**************/
.section-info {
  background-image: url(../../public/images/WFO/wave_background_slider.svg);
  padding: 0 7px 90px;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  height: auto;
  position: relative;
}

.prg-info {
  font-size: 14px;
  color: #000;
  font-family: Quicksand-Medium;
}

.prg-condition {
  font-size: 12px;
  color: #000;
  font-family: Quicksand-Medium;
}

.condition-btn {
  top: -38px;
  position: relative;
}


@media only screen and (max-width: 991.5px) {

  /**********header-mobile**********/
  .header {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 37px #00000014;
    border-radius: 0px;
    padding-left: 0;
    padding-right: 0px;
  }
  .box-language{
    display: flex;
    flex-direction: row;
  }
  .menu-middle{
    margin-top: 10px;
  }
  .header .box-header-commercial,sr
  .header .box-header-support {
    text-align: center;
  }

  ul.navbar-nav.ml-auto.btn-language a {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 3px 4px;
    transition: .3s all;
    border: 1px solid #ECECEC;
    border-radius: 43px;
  }

  .heurs-travail {
    text-decoration: none;
    display: none !important;
  }

  .contact-wp-menu {
    font-size: 10px;
  }

  .logo-header, .logo-header-en {
    margin-top: 0;
    padding-bottom: 5%;
    padding-left: 16px;
  }

  .container-header button.navbar-toggler {
    padding-right: 16px;
  }

  .container-header {
    display: grid;
    position: relative;
    padding: 0;
  }

  .container-header-1 {
    order: 1;
    border-top: solid #8080800f;
    position: fixed;
    width: 100%;
    background-color: #FFF;
    margin-top: 14px;
    top: 85px;
    transform: translate(10px, 0px);
    height: 30px;
    z-index: 1;
  }

  .container-header-2 {
    order: 0;
  }

  .alignement-nav {
    height: 100vh;
    background: rgb(255, 249, 244);
    background: linear-gradient(180deg, rgba(255, 249, 244, 1) 0%, rgba(255, 245, 235, 1) 50%, rgba(255, 251, 247, 1) 100%);
    margin-left: 0;
    margin-top: 35px;
  }

  .alignement-nav .navbar-nav {
    align-items: end !important;
    margin-top: 30px;
  }

  .alignement-nav .navbar-nav li.nav-item a {
    font-size: 20px !important;
    text-align: end;
  }

  .header .dropdown-menu.show {
    position: absolute;
    right: 0;
    left: inherit;
  }

  .header .dropdown-menu.show a.dropdown-item {
    font-size: 16px !important;
  }

  .alignement-nav .navbar-nav a.nav-link.active {
    color: #ED5F11 !important;
    border-bottom: none !important;
  }

  /****************footer-mobile*******************/
  .bloc-adr-footer {
    margin-top: 0;
  }

  .footer-bloc-3-end {
    margin-block: 4px 13px;
    width: 93%;
  }

  .footer-form {
    padding-left: 0%;
  }

  .form-newsletter {

    margin-left: -6%;
  }

  .condition-footer-label {
    width: 321px;

  }

  .condition-footer {
    display: flex;
    justify-content: center;
  }

  .condition-footer {
    margin-left: -5%;
  }

  .titre-footer {
    color: black;
  }

  .img-linkedIn {
    margin-left: 1%;
    margin-top: -2%;
  }

  .footer-bloc-3 {
    margin-block: 4px 13px;
  }

  .slider-we-section h1,
  .slider-we-section h1 strong {
    text-align: center;
    font-size: 25px;
  }

  .separator-slider-we {
    border-radius: 0 0 33px 33px;
    height: 200px;
    margin-top: 15%;
  }

  .btn-condition {
    margin: 0 auto;
  }

  .condition-btn {
    top: -20px;
    position: relative;
  }

  /********wp mobile*************/
  .separator-slider-wp-section {
    border-radius: 0 0 33px 33px;
    height: 200px;
    margin-top: 15%;
  }

  .slider-wp-section h1,
  .slider-wp-section h1 strong {
    text-align: center;
    padding-top: 65px;
    font-size: 25px;
    padding-bottom: 0;
  }

  .slider-wp-section p {
    text-align: center;
  }

  .separator-slider-wp-section:before {
  background-image: url('../../public/images/WP/logo_wp-1.svg');
    position: absolute;
    margin: 0;
    padding: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /* opacity: 0.65; */
    transform: translate(12%, -50%);
    top: 97%;
    left: 10%;
  }


  .box-slider-wp {
    padding: 7%;
  }

  .section-mobile h1 {
    text-align: center;
  }

  .section-mobile {
    text-align: center;

  }

  .contact-menu {
    font-size: 10px;
    font-weight: bold;
  }

  .icone-menu {
    padding-right: 4px;
  }

  .prg-slider-wp {
    text-align: center;
  }

  .text-slider-wp {
    padding-top: 25%
  }



  .titre-slider-wp {
    text-align: center;

  }

  .img-slider-wp {

    margin-left: 0;

  }

  .expertice-imgs h5 {
    font-size: 25px;
    color: #fff;
    font-family: Quicksand-Medium;
    position: relative;
    left: 24%;
    top: 31%;
  }

  .section-footer {
    margin: 0;
  }

  .section-client .carrousel-box-wp .owl-carousel .owl-item img {
    width: 90%;
    margin: 0 auto;
  }

  .section-client .carrousel-box-wp .owl-carousel .owl-item .card .card-body {
    text-align: center;
  }

  .section-client .card h6 {
    padding-left: 20px;
  }

  .section-client .card-body h6 {
    padding-left: 0;
  }

  .section-client .card-body h6::before {
    content: " ";
  }

  /***********slider-we*************/
  .separator-slider-we-section:before {
    background-image: url('../../public/images/WE/logo_we.svg');
    content: ' ';
    position: absolute;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    top: 100%;
    left: 50%;
    width: 160px;
    height: 160px;
    background-size: cover;

  }



  .slider-we-section {
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    height: auto;
    position: relative;
    margin: 0;
    padding: 0;

  }

  .slider-we-section h1 p {
    text-align: center;

  }

  .slider-we-section p {
    text-align: center;

  }

  .section-mobile {
    background-size: 66%;
    border-radius: 80px;
    background: linear-gradient(#F8E7F7, #FFFFFF00);
    background-position: top;
    margin-top: 20%;
    margin: 0 !important;
  }

  .section-bien_plus_encore {
    transform: translate(0%, 0%);
    margin: 0 !important;
  }


  .section-bien_plus_encore .text-bloc {
    padding-top: 30px;
  }

  .section-inscription {
    background-size: 66%;
    border-radius: 80px;
    background: linear-gradient(#F8E7F7, #FFFFFF00);
    background-position: top;
    margin-top: 20%;
    transform: translate(0%, 0%);
  }

  /***********slider-wp*************/
  .separator-slider-wp-section:before {
  background-image: url('../../public/images/WP/logo_wp-1.svg');
    content: ' ';
    position: absolute;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    top: 100%;
    left: 50%;
    width: 160px;
    height: 160px;
    background-size: cover;

  }

  .slider-wp-section {
    margin: 0;
    padding: 0;
  }

  .separator-slider-wp-section {
    border-radius: 0 0 33px 33px;
    height: 200px;
    margin-top: 15%;
  }

  .slider-wp-section h1 p {
    padding-top: 25%;

  }

  .section-reactivite {
    padding: -8% 55px;
    background-size: 66%;
    border-radius: 80px;
    background: linear-gradient(#E6F0FD, #FFFFFF00);
    margin: 0px;
    transform: translate(0%, 0%);
  }

  /***********slider-wp*************/
  .separator-slider-wts-section:before {
    background-image: url('../../public/images/WTS/rond_wh.svg');
    content: ' ';
    position: absolute;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    top: 100%;
    left: 50%;
    width: 160px;
    height: 160px;
    background-size: cover;

  }

  .slider-wts-section {
    margin: 0;
    padding: 0;
  }

  .separator-slider-wts-section , .separator-slider-we-section {
    border-radius: 0 0 33px 33px;
    height: 200px;
    margin-top: 15%;
  }
 

  .slider-we-section h1,
  .slider-we-section h1 strong {
    padding-top: 65px;
    text-align: center;
    font-size: 25px;
    padding-bottom: 0;
  }
  .slider-wts-section h1,
  .slider-wts-section h1 strong {
    padding-top: 65px;
    text-align: center;
    font-size: 25px;
    padding-bottom: 0;
  }

  .slider-wts-section p {
    text-align: center;

  }


  /* .section-solution .owl-stage .owl-item.active.max-width-40 {
    width: 40% !important;
    flex: 0 0 auto !important;
    max-width: 40%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
    margin-right: 0 !important;
  }

  .section-solution .owl-stage .owl-item.active.max-width-50 {
    width: 50% !important;
    flex: 0 0 auto !important;
    max-width: 50%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
    margin-right: 0 !important;
  } */

  .img-solution {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  /********carrousel************/
  .img-box {
    height: 180px !important;
    position: relative !important;
    border-radius: 37px !important;
    margin-bottom: 20px !important;
  }


  .img-box h5 {
    font-size: 25px !important;
    color: #fff !important;
    font-family: Quicksand-Medium !important;
  }

  .c,
  .section-experience .owl-carousel .owl-nav button.owl-next {
    display: none;
  }


  .section-experience .owl-carousel .owl-nav button.owl-prev,
  .section-experience .owl-carousel .owl-nav button.owl-next {
    display: none;
  }

  .section-experience .owl-carousel .owl-nav button.owl-prev,
  .section-experience .owl-carousel .owl-nav button.owl-next,
  .section-experience .owl-carousel button.owl-dot {
    color: #4BBDCF;
    font-size: 60px;
  }

  .section-solution.wts .owl-carousel .owl-nav button.owl-prev,
  .section-solution .wts .owl-carousel .owl-nav button.owl-next,
  .section-solution.wts .owl-carousel button.owl-dot {
    color: #4BBDCF;
    font-size: 60px;

  }

  .img-cov-2 {
    height: 50px;
    top: -24px;
    left: 4%;

  }

  .custom-line-bloc2.owl-carousel.owl-carousel-wfo-2.owl-theme .carrousel-box .box-content p::before {
    border: none;
    width: 0;
    margin-right: 0;
  }

  .custom-line-bloc2 .carrousel-box .box-content p {
    text-align: left;
    color: #000;
    width: 70%;
  }

  .custom-line-bloc2 .carrousel-box .box-content {
    margin-left: 30px;
  }

  /**************agence*********************/
  .slider-agence-section {
    padding: 0px !important;
  }

  .separator-slider-agence-section {
    border-radius: 33px;
  }

  .separator-slider-agence-section:before {
    background-image: url('../../public/images/agence/logo_netisse.svg');
    content: ' ';
    position: absolute;
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    top: 100%;
    left: 50%;
    width: 160px !important;
    height: 160px !important;
    background-size: cover;
  }

  img.img-fluid.img-slider-agence {
    margin-top: -102px;
  }

  .slider-agence-section {
    background-image: url(../../public/images/WP/wave_background_force.svg);
    padding: 21px 60px;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    height: auto;
    position: relative;
    margin: 0 !important;
    margin-top: 140px !important;
    padding-bottom: 10% !important;
  }

  .separator-slider-agence-section img {
    height: auto !important;
  }
  .is_header button.owl-prev{
    top: 13%;
    left: -8%;
  }
  .is_header button.owl-next{
    top: 13%;
    right: -8%;
  }
  .separator-slider-agence-section {
    position: relative !important;
    border-radius: 0 0 33px 33px;
    height: 200px;
    margin-top: 15%;
  }

  .slider-agence-section h1,
  .slider-agence-section h1 strong {
    text-align: center !important;
    font-size: 25px;
    padding-top: 10%;
    padding-bottom: 10px;
  }

  .section-nos-solution h1 {
    font-size: 25px;
  }

  .card-nos-solution,
  .card-nos-solution-wfo,
  .card-nos-solution-we,
  .card-nos-solution-ws,
  .card-nos-solution-wpp {
    width: 100%;
    margin-bottom: 15%;
  }

  .section-nos-solution.m-5 .col-md-12 {
    width: 100% !important;
  }

  /******************avantage***************/
  .text-box-avantage {
    text-align: center;
  }


  .section-avantage h2 {
    margin-bottom: 10%;
  }

  .avantage-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .section-avantage h2 {
    font-size: 25px;
    margin-bottom: 10%;
  }

  .avantage-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  /***************wts***********/
  .section-win-win {
    background-size: 66%;
    border-radius: 80px;
    background-color: linear-gradient(#E9F5F5, #FFFFFF00);
    background-position: top;
    margin-top: 20%;
    margin: 0;
  }

  .section-benefices {
    padding: 6% 60px 5% 60px;
    background-size: 66%;
    border-radius: 80px;
    background-position: top;
  }

  .section-solution {
    background-image: none;
  }


  .section-box-we {
    margin: 0 50px 50px 50px;
    border-radius: 80px;
    transform: translate(0%, 0%);
  }

  .card-benefices-entreprise h5:before,
  .card-benefices-client .card-body h5:before,
  .section-experience-content::before {
    background-image: none;
  }

  .card-benefices-entreprise .card-body,
  .card-benefices-client .card-body {
    width: 290px;
    padding: 10%;
  }

  a[href^="tel"] {
    -webkit-touch-callout: none;
    font-size: 7px;
    /* Modifier la taille de police */

  }

  .section-experience-content .img-box {
    position: relative;
  }

  .img-box h5 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .section-experience .container-solution {
    overflow: hidden;
  }

  .section-experience .container-solution .owl-carousel .owl-stage {
    width: max-content !important;
  }

  /* .section-experience .container-solution .owl-carousel .owl-stage-outer {
    overflow: inherit;
  } */

  /* .section-experience .container-solution .owl-carousel.owl-drag .owl-item {
    margin-right: 25px !important;
  } */

  .section-experience-content h4 {
    font-size: 22px;
  }

  .section-experience-content {
    padding: 0;
    background: none;
  }

  .section-experience .carrousel-experience .owl-carousel .owl-item img {
    width: 60px;
  }

  .section-outil-technologique .text-bloc>h4::before {
    top: 47px;
  }

  .img-solution {
    width: 130px;
    height: 130px;
  }

  /***********slider-we*************/
  .separator-slider-wfo-section:before {
    background-image: url('../../public/images/WFO/logo_wfo.svg');
    content: ' ';
    position: absolute;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    top: 100%;
    left: 50%;
    width: 160px;
    height: 160px;
    background-size: cover;

  }

  .separator-slider-wfo-section {
    border-radius: 0 0 33px 33px;
    height: 200px;
    margin-top: 15%;
  }

  .slider-wfo-section {
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    height: auto;
    position: relative;
    margin: 0;
    padding: 0;

  }

  .slider-wfo-section h1,
  .slider-wfo-section h1 strong {
    padding-top: 65px;
    text-align: center;
    font-size: 25px;

  }

  .notre-solution h1,
  .notre-solution h1 strong {
    font-size: 25px;
  }

  .slider-wfo-section p {
    text-align: center;

  }

  .section-cartographie {
    margin: 0px 0 30px 0;
  }

  .img-inscription {
    transform: translate(0, -35px);
  }

  .section-mobile .orderBox1 {
    order: 1;

  }

  .section-mobile .orderBox2 {
    order: 0;
  }

  .section-bien_plus_encore .orderBox1 {
    order: 1;
  }

  .section-bien_plus_encore .orderBox2 {
    order: 0;
  }

  .section-outil-technologique .orderBox1 {
    order: 1;
  }

  .section-outil-technologique .orderBox2 {
    order: 0;
  }



  .section-expertice {
    position: relative;
    padding: 0% 0%;
    background: none;
    margin: 10px 0;
  }

  .section-expertice .img-mobile.wp {
    position: relative;
  }

  .section-expertice .img-mobile.wp .titre-accordien-1 h3 strong {
    display: block !important;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 82%;
    color: #fff;
    font-size: 22px;
    white-space: nowrap;
  }

  .section-expertice h3 strong {
    display: none;
  }

  .section-expertice .img-mobile .titre-accordien h3 strong {
    position: absolute;
    z-index: 2;
    top: 71%;
    left: 52%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 22px;
    display: block;
  }

  .section-expertice h3 ::before {
    content: "";
    font-weight: 900;
    color: #fff
  }

  .section-expertice .col-md-4 {
    width: 100%;
  }

  .form-contact-content input {
    width: 100%;
  }

  .contact-box {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .condition {
    top: inherit;
  }

  .condition-btn {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .section-footer {
    padding-top: 30px;
  }

  .footer-bloc-3-end .footer-link {
    justify-content: center;
  }

  .footer-bloc-3 {
    display: flex;
    justify-content: center;
  }

  .custom-line-bloc2 .carrousel-box .owl-carousel.owl-drag .owl-item:nth-child(2) {
    padding-top: 0%;
  }

  .custom-line-bloc2 .carrousel-box .owl-carousel.owl-drag .owl-item:nth-child(3) {
    padding-top: 0%;
  }

  /******section-ref********/
  .section-ref {
    margin: 50px 0;
  }
  .section-solution-ref {
    padding: 46% 0% 6% 0%;
  }
  .cat-content .img2 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 32%;

  }

  .section-ref .all-card {
    border-radius: 57px !important;
    height: auto !important;
    margin-bottom: 30px !important;
  }

  /***********WFO************/
  .custom-line .owl-dots {
    display: none;
  }

  .custom-line .carrousel-box .owl-dots {
    display: block !important;
    margin-top: 0;
  }

  .custom-line-bloc2 {
    margin-bottom: 30px;
  }

  .section-cartographie .box-content::before {
    width: 0;
  }

  .section-cartographie .box-content p {
    padding-left: 10px;
    padding-right: 10px;
  }

  .section-outil-technologique {
    margin: 0 0;
  }
}
@media only screen and (min-width: 767px) {
  .input.form-check-input.cheeck-condition {
    margin-top: 3px;
    margin-left: -53px;
    width: 20px;
    margin-left: -55px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-we-section {
    margin: 0px 0 25% 0;
  }

  .section-outil-technologique-1 {
    display: none;
  }

  .section-experience-content h2 {

    display: none;
  }

  .section-solution-en-situation img {
    width: 100%;
    height: auto;
  }

  .infoline-menu {
    font-weight: 700;
    font-size: 9px;

  }

  .slider-wts-section .btn-play-vd-slider {
    margin: 0 auto;

  }

  .slider-wts-section p {
    margin-bottom: 40px;
  }

  .slider-wfo-section .btn-play-vd-slider {
    margin: 0 auto;
  }

  .slider-agence-section p {
    text-align: center;
  }

  .card-nos-solution,
  .card-nos-solution-wfo,
  .card-nos-solution-we,
  .card-nos-solution-ws,
  .card-nos-solution-wpp {
    width: 100%;
    margin-bottom: 15%;
  }

  .section-box-we h4 {
    font-size: 25px;
    text-align: center;
    margin-top: 5%;

  }

  .slider-we-section .btn-play-vd-slider {
    margin: 0 auto;
  }

  .section-bien_plus_encore h1 {
    text-align: left;
    font-size: 25px;
  }

  .section-solution.we h2 {
    padding-bottom: 25px;
    font-size: 25px;
    padding-top: 13%;
  }

  .btn-ref-retour {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    top: -18px;
    left: 38%;
  }

  .box-img-cov {
    padding-top: 7%;
  }

  .section-mobile h1 strong,
  .section-mobile h1 {
    font-size: 25px;

  }

  .section-inscription h1 strong {
    font-size: 25px;
  }

  .titre-contact-wts .titre-contact,
  .titre-contact-wfo .titre-contact,
  .titre-contact-we .titre-contact,
  .titre-contact-wp .titre-contact {
    font-size: 25px;
  }

  .box-content {
    padding: 0px;
  }

  .section-win-win .text-win-win {
    display: none;
  }

  .section-win-win h2 {
    display: none;
  }

  .section-bien_plus_encore .img-mobile {
    transform: translate(0%, 0%) !important;
    margin: 0 !important;
  }

  .section-mobile .img-mobile {
    transform: translate(0px, -80px) !important;
  }

  .section-with-background h2,
  .section-with-background h2 strong {
    text-align: center;
    font-size: 25px;
  }

  .section-with-background-data h2,
  .section-with-background-data h2 strong {
    text-align: center;
    font-size: 25px;
  }

  .btn-mobile-wp {
    font-size: 14px;
    width: 98px;
    height: 44px;
    background: #1C72BD 0% 0% no-repeat padding-box;
    font-size: 13px;
    font-weight: bold;
    border-radius: 97px;
    border: none;
    color: #fff;
    text-align: center;
    display: block;
    margin: auto;
    margin-top: 2%;

  }

  .section-with-background p {
    text-align: center;
  }

  .section-with-background-data p {
    text-align: center;
  }

  .container.bloc-multi p {
    text-align: center;
  }

  .container.bloc-multi h3,
  .container.bloc-multi h3 strong {
    text-align: center;
    font-size: 22px;

  }

  .section-solution-en-situation h2,
  .section-solution-en-situation h2 strong {
    font-size: 25px !important;
  }

  .section-with-background .box-downsection-with-background.visible-mobile {
    margin-left: 0;
    margin-top: -22px;
  }

  .section-with-background-data .box-downsection-with-background.visible-mobile {
    margin-left: 0;
    margin-top: -22px;
  }

  .section-cartographie h2,
  .section-cartographie h2 strong {
    font-size: 25px;
    text-align: center;
  }

  .section-solution.wfo h1 {
    padding-bottom: 0px;
    font-size: 25px;
    padding-top: 13%;
  }

  .section-solution-en-situation .text-img-opt .col-md-7 {
    margin-top: 0;
  }

  .titre-outil-tech-wts h2,
  .titre-outil-tech-wts h2 strong {
    font-size: 25px;
    padding-top: 4%;
    padding-bottom: 0px;

  }

  .col-md-6.align-self-center.box-downcontainer.bloc-multi {
    margin-top: 0 !important;
  }

  .titre-expertice-wp h2 {
    font-size: 25px;
    padding-bottom: 0px;
  }

  .titre-expertice-wp strong {
    font-size: 25px;
  }

  .section-contact {
    padding: 0% 20px 0;
  }

  .section-pedagogier h1 strong {
    font-size: 25px;
  }

  .section-reactivite h1 {
    text-align: center !important;
    font-size: 25px;
  }

  .section-solution.wts h1 {
    color: #1E3250;
    padding-bottom: 0 !important;
    padding-top: 50px;
  }

  .section-solution.wts h1 {
    font-size: 25px;

  }

  .video-play {
    width: 50px;
    height: 50px;
    top: 44%;
  }

  .video-bloc-center {
    width: 100% !important;
  }

  .custom-line-bloc2 .owl-theme .owl-dots .owl-dot span,
  .custom-line .owl-theme .owl-dots .owl-dot span {
    background: #c9428a75;
  }

  .carrousel-box-wp .owl-theme .owl-dots .owl-dot span {
    background: #3d76f391;

  }

 
  

  .section-reactivite p {
    text-align: center;
  }

  .section-mobile-wp .bg-1 {
    background-image: url('../../public/images/wp-mobile/arrow.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;

  }

  .section-mobile-wp .bg-2 {
    background-image: url(../../public/images/wave_background\ slider.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    position: relative;
    background-position: center bottom;


  }
  .cat-content {
    padding-top: 31%;
  }
  .separator-slider-we-section {
    height: 248px;
    border-radius: 0 0 33px 33px;


  }

}

/* Masquer l'élément sur les écrans de bureau */
@media (min-width: 992px) {
  .section-mobile-wp {
    display: none;
  }

  
  .section-accompagnement {
    display: block;

  }
}

/* Afficher l'élément sur les écrans mobiles */
@media (max-width: 991px) {
  .section-mobile-wp {
    display: block;
  }

  h1 {

    font-size: 25px;
  }

  .section-mobile-wp h1 {
    font-size: 24px;
    color: #112F6D;
    padding-bottom: 15px;
    text-align: left;
    letter-spacing: 0px;
    font-family: Quicksand-Medium;
  }

  .section-mobile-wp h1 strong {
    font-size: 24px;
    color: #417CFF;
    font-family: Quicksand-Medium;
  }

  .section-mobile-wp p {
    font-family: Quicksand-Medium;

  }

  .section-accompagnement {
    display: none;

  }

  .section-with-background img {
    transform: translate(0px, -13px) !important;
  }

  .section-with-background-data img {
    transform: translate(12px, -19px) !important;
  }

  .section-client h1 {
    font-size: 25px;
  }

  .section-client h1 strong {
    font-size: 25px;
  }

  .section-with-background {
    margin: 21px 0;
  }

  .section-with-background {
    padding: 0px;
  }

  .section-with-background-data {
    margin: 21px 0;
  }

  .section-with-background-data {
    padding: 0px;
  }

  .section-experience h2 {
    font-size: 25px;
  }

  .section-experience h2 strong {
    font-size: 25px;
  }

  .section-benefices h2 strong {
    font-size: 25px;
  }

  .section-benefices h2 {
    font-size: 25px;
  }

  .titre-contact-we .titre-contact::before {
    padding: 0px;
  }

  .titre-contact-wp .titre-contact::before {
    padding: 0px;
  }

  .titre-contact-wts .titre-contact::before {
    padding: 0px;
  }

  .titre-contact-wfo .titre-contact::before {
    padding: 0px;
  }

  .section-expertice .wp .titre-accordien {
    display: block;
  }
  .container.reveal{
    overflow-x: initial;
  }
  .body-page-we .container.reveal{
    overflow-x: initial;
  }
}




/*  AJOUT MACARON REF */
.section-ref .card-img-macaron {
  background: #fff;
  padding: 6px 17px;
  border-radius: 30px;
  position: absolute;
  top: /*243px;*/ 230px;
  right: 80px;
}

@media only screen and (max-width: 1399px) {
  .section-ref .card-img-macaron {
      top:  229px; /*200px;*/
  }  
}

@media only screen and (max-width: 1200px) {
  .section-ref .card-img-macaron {
   top: 233px; /*163px;*/
  right: 50px;
  height: 35px;
  }  
}

@media only screen and (max-width: 991.5px) {
  .cat-content .img2 {
    padding-top: 20% !important;
  }
}

@media only screen and (max-width: 991px) {
  .section-ref .card-img-macaron {
      right: 50%;
      transform: translate(50%, 0);  
      /*top: 108px;*/
  }
.section-ref  .card-img-ref.img2  {
      /*width: 120px;*/
      box-shadow: none;
      position: absolute;
      top: -18px;
      right: 50%;
      transform: translate(50%, 0);
      left: inherit;

      /* new*/
      height: 0px;
      border-radius: 35px;
      width: 148px;
  }
  .section-ref .card-img-ref.img1  {
      margin-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {	
  .section-ref .card-img-macaron {
    /*top: 160px;*/
  }
}
@media only screen and (max-width: 575px) {	
.section-ref .box-img {
  height: 170px;
}

  .section-ref .card-img-ref.img1 {
  margin-bottom: 40px;
  height: 160px;
  object-fit: cover;
  border-radius: 40px;
}
}

@media only screen and (max-width: 575px) {	
  .section-ref .box-img {
    height: 170px;
  }
}

@media only screen and (max-width: 575px) {
  .section-ref .card-img-macaron {
      top:  134px; /*200px;*/
  }  
}

/*************animation***********/
/* .fade-bottom {
  animation-name: fade-bottom;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}
.fade-left {
  animation-name: fade-left;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}
.fade-right {
  animation-name: fade-right;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}
.fade-top {
  animation-name: fade-top;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}
.section-benefices{
  transform: translate(0px, 40px); 
}
.text-win-win.active::before{
  animation-name: left-right-custom;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}

.img-mobile.wp{
  transform: translate(-50px, 0px);
}


.left-right {
  animation-name: left-right;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}

.box-downsection-with-background ,.section-avantage , .section-nos-solution , 
.section-footer , .titre-outil-tech-wts , .section-outil-technologique , 
.section-outil-technologique-1 , .section-solution , .section-contact , 
.carrousel-box , .section-solution-en-situation , .section-cartographie .row,
.section-inscription , .section-box-we , .section-bien_plus_encore , 
.box-downsection-reactivite , .section-pedagogier .img-inscription , 
.section-accompagnement , .section-ref , .section-contact-page{
  transform: translate(0px, 40px);
}
.section-cartographie h2{
  transform: translate(0, -40px);
}

.carrousel-box-wp .item:first-child{
  transform: translate(40px, 0px);
}
.carrousel-box-wp .item:last-child{
  transform: translate(-40px, 0px);
}
.carrousel-box-wp .item.active:first-child{
  animation-name: fade-left-custom;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}
.carrousel-box-wp .item.active:last-child{
  animation-name: fade-right-custom;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}


.box-downsection-with-background-data , .box-downsection-expertice {
  transform: translate(50px, 0px);
}

.box-win {
  transform: translate(50px, 0px);
}

.text-animation>div{
  transform: translate(0px, 40px);
  animation: fade-top 1s ease-in;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

.section-experience button.owl-next.active {
  animation-name: arrow-animation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}


.section-experience button.owl-prev.active {
  animation-name: arrow-animation-2;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}
.section-experience-content.active::before{
  animation-name: fade-bottom;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}



@keyframes fade-bottom {
  from { transform: translate(0, -40px); }
  to { transform: translate(0, 0); }
}


@keyframes fade-top {
  from { transform: translate(0px, 40px); }
  to { transform: translate(0px, 0px); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}


@keyframes fade-left-custom {
  from { transform: translate(-40px, 0px); }
  to { transform: translate(0px, 0px); }
}
@keyframes fade-right-custom {
  from { transform: translate(40px, 0px); }
  to { transform: translate(0px, 0px); }
}


@keyframes fade-left {
  from { transform: translate(50px, 0px); }
  to { transform: translate(0px, 0px); }
}
@keyframes fade-right {
  from { transform: translate(-50px, 0px); }
  to { transform: translate(0px, 0px); }
}

@keyframes left-right {
  from { left: -50%;}
  to { left: -31%; }
}
@keyframes left-right-custom {
  from { left: -50%;}
  to { left: -31%; }
}

@keyframes arrow-animation {
  from { right: -18%; }
  to { right: -11%; }
}
@keyframes arrow-animation-2 {
  from { left: -16%; }
  to { left: -9%; }
}
@keyframes background-animation {
  from { background-position: 0px 130%;}
  to { background-position: 0px 100%;}
}
@keyframes rotation {
  from { transform: translate(-50%, -50%) rotate(300deg); }
  to { transform: translate(-50%, -50%) rotate(360deg);}
}
@keyframes left-center {
  from { left: -35%; }
  to { left: -25%; }
}


@keyframes left-right {
  from { transform: translate(-25px, 0);}
  to { transform: translate(0, 0); }
} */

@media screen and (max-width: 991.5px){
  #navbarSupportedContent {
    display: flex;
    flex-flow: column-reverse;
    height: 100vh;
    justify-content: flex-end;
  }
  #navbarSupportedContent .box-language li a {
    padding: 0px !important;
    font-size: 13px !important;
    text-align: center !important;
    line-height: 39px;
  }
  #navbarSupportedContent.collapse:not(.show) {
    display: none;
  }
}
@media screen and (min-width: 767.5px) and (max-width: 1199.5px){
  .card-benefices-client{
    transform: translate(70px, 0px);
  }
  .card-benefices-entreprise{
    transform: translate(-70px, 0px);
  }
}

.link_footer a {
  font-size: 12px;
  font-family: Quicksand-Medium;
  color: #E86012;
}
#cookies__wrapper .modal-footer{
  display: block;
}
 
.define-msg-cond {
  font-size: 12px;
  font-weight: 400;
  /*padding-left: 10px;*/
  position: relative;
}
.margin-ch {
  margin-top: 0px;
}
.logohead {
  width: 70%;
  /* margin-top: -20px; */
}
.dropdown-submenu > .dropdown-menu {
  top: 10px;
  left: 100.5%;
  margin-top: -5px;
  height: 128px;
  border-radius: 13px;
  opacity: 1;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu .dropdown-toggle::after {
  display: none;
}

.dropdown-item i {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  border-right: 2px solid #E86012 !important;
  border-top: 2px solid #E86012 !important;
  transform: rotate(45deg);
  border-left: 0 solid transparent !important;
  left: 35px;
  position: relative;

}

@media only screen and (max-width: 991.5px) {
  .header .dropdown-submenu > .dropdown-menu.show {
    position: static;
  }
  .dropdown-item i {
    transform: rotate(135deg);
    left: 14px;
  }
  .header .dropdown-submenu > .dropdown-menu {
    border-radius: 0;
    display: block;
    padding-right: 35px;
    margin-top: 0;
    height: auto;
    background-color: #f7f7f7;
  }
}
@media only screen and (max-width: 392px) {
  .navbar-brand {
    width: 70% !important;
  }
  .logo-header, .logo-header-en {
    margin-top: 0 !important;
  }
}